export const SNAPSHOT_METRICS_LOADING = "SNAPSHOT_METRICS_LOADING";
export const SNAPSHOT_METRICS_SUCCESS = "SNAPSHOT_METRICS_SUCCESS";

export type SnapshotMetrics = {
  id: string;
  assetNumber: string;
  dates: string[];
  systemTemp: (number | null)[];
  batteryLoadCurrent: (number | null)[];
  lastLoadCurrent: number;
  lastSystemTemp: number;
  lastDate: string;
  lastStateOfCharge: number;
  stateOfCharge: (number | null)[];
  lastOEMVoltage: number;
  OEMVoltage: (number | null)[];
  lastFuelLevel: number;
  fuelLevel: (number | null)[];
  chargeRemaining: number;
  signalStrength: number;
  SPSystemStatus: string;
  SPOnTimes: string[];
};

export interface SnapshotMetricsLoading {
  type: typeof SNAPSHOT_METRICS_LOADING;
}

export interface SnapshotMetricsSuccess {
  type: typeof SNAPSHOT_METRICS_SUCCESS;
  payload: Array<SnapshotMetrics>;
}

export type SnapshotMetricsDispatchTypes =
  | SnapshotMetricsLoading
  | SnapshotMetricsSuccess;
