export const METRICS_LOADING = "METRICS_LOADING";
export const METRICS_SUCCESS = "METRICS_SUCCESS";

export type Metrics = {
  id: string;
  assetNumber: string;
  dates: string[];
  systemTemp: (number | null)[];
  batteryLoadCurrent: (number | null)[];
  lastLoadCurrent: number;
  lastSystemTemp: number;
  lastDate: string;
  lastStateOfCharge: number;
  stateOfCharge: (number | null)[];
  lastOEMVoltage: number;
  OEMVoltage: (number | null)[];
  lastFuelLevel: number;
  fuelLevel: (number | null)[];
  chargeRemaining: number;
  signalStrength: number;
  SPSystemStatus: string;
  SPOnTimes: string[];
  SPChargingTimes: string[];
};

export interface MetricsLoading {
  type: typeof METRICS_LOADING;
}

export interface MetricsSuccess {
  type: typeof METRICS_SUCCESS;
  payload: Array<Metrics>;
}

export type MetricsDispatchTypes = MetricsLoading | MetricsSuccess;
